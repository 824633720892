const LIST_PATH = '/taskList'
const CS_LIST_PATH = '/crowdSourceTaskList'
const FEEDBACK_PATH = '/abnormalFeedback'

export default {
  data() {
    return {
      showBackBtn: true,
      fromTasklist: false,
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.path === LIST_PATH || to.path === FEEDBACK_PATH || from.path === CS_LIST_PATH) {
      next(vm => {
        vm.fromTasklist = true
      })
    } else {
      next()
    }
  },
  methods: {
    handleBack() {
      if (this.fromTasklist) {
        this.$router.go(-1)
      } else {
        this.$router.replace({ path: LIST_PATH, replace: true })
      }
    },
  },
}
