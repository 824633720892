import { isDate, isString } from './type'
/**
 * 日期格式化
 * @param  {[type]} date [description]
 * @param  {[type]} fmt  [description]
 * @return {[type]}      [description]
 */
export const formatDate = (date, fmt = 'yyyy.MM.dd') => {
  if (!date) return ''
  if (!isDate(date)) {
    if (isString(date)) {
      date = parseInt(date)
    }
    date = new Date(date)
  }
  var o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  }
  if (/(y+)/i.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in o) {
    let reg = new RegExp('(' + k + ')')
    if (k === 'd+' || k === 'h+') {
      reg = new RegExp('(' + k + ')', 'i')
    }
    if (reg.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
    }
  }
  return fmt
}
