import { getStore, setStore } from './storage'
import { 
  UPLOAD_FILE_UNIQUEID_LIST,
  ACTIVITY_ORDER_UNIQUEID_LIST,
  ACTIVITY_ORDER_TYPE,
} from '@/constant'

const MAX_STORAGE_LENGTH = 500


export function updateUniqueFileList(files = [], userTaskId = '') {
  let uniqueList = getStore(UPLOAD_FILE_UNIQUEID_LIST) || []
  let activityOrderUniqueList = getStore(ACTIVITY_ORDER_UNIQUEID_LIST) || []
  if (!Array.isArray(uniqueList)) {
    uniqueList = []
  }
  files.forEach(file => {
    if (file.uniqueId) {
      uniqueList.push(file.uniqueId)
    }
    if (file.imageType === ACTIVITY_ORDER_TYPE) {
      activityOrderUniqueList.push(userTaskId)
    }
  })
  if (uniqueList.length > MAX_STORAGE_LENGTH) {
    uniqueList.splice(0, uniqueList.length - MAX_STORAGE_LENGTH)
  }

  if (activityOrderUniqueList.length > MAX_STORAGE_LENGTH) {
    activityOrderUniqueList.splice(0, activityOrderUniqueList.length - MAX_STORAGE_LENGTH)
  }
  setStore(UPLOAD_FILE_UNIQUEID_LIST, uniqueList)
  setStore(ACTIVITY_ORDER_UNIQUEID_LIST, activityOrderUniqueList)
}

export function updateStore(storeName, value = '') {
  if (!storeName) return;
  let storeList = getStore(storeName) || []
  if (!Array.isArray(storeList)) {
    storeList = []
  }
  storeList.push(value);

  if (storeList.length > MAX_STORAGE_LENGTH) {
    storeList.splice(0, storeList.length - MAX_STORAGE_LENGTH)
  }

  setStore(storeName, storeList)
}