<template>
  <div class="headernav">
    <div
      class="left-btn"
      @click="handleBack"
    >
      <van-icon name="arrow-left" />
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  props: {
    title: {
      type: String,
      default: '返回任务列表',
    },
  },
  methods: {
    handleBack() {
      this.$emit('onBack')
    },
  },
}
</script>

<style rel="stylesheet/less" lang="less" scoped>
.headernav {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  font-size: 14px;
  color: #666;
  background: #fff;
  border-bottom: 1px solid #ddd;
  .left-btn {
    display: flex;
    align-items: center;
    height: 50px;
    cursor: pointer;
  }
}
</style>
