<template>
  <div>
    <nav-bar
      v-if="showBackBtn"
      @onBack="handleBack"
    />
    <div
      v-if="isClose"
      class="poiTaskDistribution"
    >
      <h3>{{ poiTaskName }}</h3>
      <div>{{ jobEndTime }}</div>
      <div class="poiTaskDistributionMain">
        <div class="information">省市信息：{{ provCity }}</div>
        <div class="information copy">
          <span id="address">地址信息：{{ address || '暂无' }}</span>
          <van-button
            v-if="!!address"
            size="mini"
            class="copyBtn"
            @click="handleCopy"
          >复制</van-button>
        </div>
        <div class="information">{{ note }}</div>
        <div class="poiTaskDistributionUploadFile" v-if="uploadOptions">
          <div>
            <div class="lab">
              <span>地址选择：</span>
              <span
                class="lab-demo"
                @click="show = true"
              >载图示例</span>
            </div>
            <image-collection
              ref="addressImg"
              :accept="'image/*'"
              :max-count="1"
              :uploadOptions="uploadOptions"
              :suffix="'_p'"
            />
          </div>
          <div>
            <div class="lab">门店列表：</div>
            <image-collection
              ref="poiImg"
              :uploadOptions="uploadOptions"
              :accept="accept"
              :max-count="9"
              :task-id="urlParams.userTaskId"
              :job-id="urlParams.jobId"
              :check-repeat="true"
              @file-change="onFileChange"
            />
          </div>
        </div>
        <div v-else>{{uploadTips}}</div>
      </div>
      <div class="submit">
        <van-button
          class="submitBtn"
          :loading="loading"
          loading-text="提交中..."
          @click="handleSubmit"
        >
          点我提交!
        </van-button>
        <div class="feedback">如无法完成任务，请<span class="linkText" @click="clickFeedback">点击此处</span>反馈</div>
      </div>
    </div>
    <van-empty
      v-else
      description
    />
    <van-popup
      v-model="show"
      closeable
      close-icon="close"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <div class="preview-address">
        <van-image
          width="100%"
          fit="contain"
          :src="require('../../../assets/screenshot-example.png')"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import imageCollection from '../ImageCollection/Index'
import NavBar from '../components/nav-bar.vue'

import { Dialog, Toast } from 'vant'
import { ACCEPT_IMAGE, ACCEPT_VIDEO } from '@/constant'
import { getTaskDetails, getOssSecret, submitSourceUrl, addBuriedPoint } from '@/apis'
import { formatDate } from '@/tools/date'
import { checkUploadImages, copyText, getUrlParams, userAccessValid } from '../shared'
import { updateUniqueFileList } from '@/tools/file-upload'
import backListMixins from '../mixins/back-list'

export default {
  name: 'SkuTask',
  components: {
    imageCollection,
    NavBar,
  },
  mixins: [backListMixins],
  data() {
    return {
      loading: false,
      urlParams: {},
      accept: [...ACCEPT_IMAGE, ...ACCEPT_VIDEO].join(','),
      note: '距离搜索位置小于5KM的门店列表截图',
      poiTaskName: '',
      jobEndTime: '',
      provCity: '',
      address: '',
      companyId: null,
      jobId: null,
      isClose: true,
      taskStartTime: 0,
      taskEndTime: 0,
      title: '',
      show: false,
      uploadOptions: null,
      uploadTips: '',
      taskType: '',
    }
  },
  created() {
    this.urlParams = getUrlParams(this.$route.query)
    this.title = document.title
    this.getUserAccessValid()
  },
  destroyed() {
    document.title = this.title
  },
  methods: {
    // h5鉴权接口
    async getUserAccessValid() {
      const isValid = await userAccessValid(this.urlParams)
      this.isClose = isValid
      if (isValid) {
        await this.handlePoiDetails()
        await this.getOssSecret()
      }
    },
    // 获取阿里云签名
    async getOssSecret() {
        try {
            const res = await getOssSecret()
            if (res.status === '200' && res.data) {
                this.uploadOptions = res.data
                this.uploadTips = ''
            } else {
                this.uploadTips = '上传token获取失败，请刷新重试或使用APP上传'
                Toast.fail({
                    duration: 4000,
                    message: '获取签名失败，请稍后重试',
                })
            }
        } catch (e) {
            this.uploadTips = '上传token获取失败，请刷新重试或使用APP上传'
            Toast.fail({
                duration: 4000,
                message: e || '获取签名失败',
            })
        }
    },
    async handlePoiDetails() {
      try {
        let res = await getTaskDetails({ userTaskId: this.urlParams.userTaskId, status: this.urlParams.status ? +this.urlParams.status : undefined })
        if (res) {
          if (res.code !== 0) return Toast.fail(res.msg)
          const data = res.data || {}
          const time = formatDate(new Date(data.jobEndTime), 'yyyy-MM-dd HH:mm:ss')
          this.poiTaskName = data.name
          if (data.name) {
            document.title = data.name
          }
          this.jobEndTime = `有效期至：${time}`
          this.provCity = data.provinceName ? `${data.provinceName}-${data.cityName}` : `${data.cityName}`
          this.address = data.address
          this.jobId = data.jobId
          this.taskType = data.taskType
          if (data.note) {
            this.note = data.note
          }
          if (data.detail) {
            const details = JSON.parse(data.detail)
            if (details.cityId) {
              this.urlParams.cityId = details.cityId
            }
            this.companyId = details.companyId
          }
        }
      } catch (err) {
        Toast.fail(err.message)
      }
    },
    // 提交任务接口
    async fetchTaskNew(submitInfo, imgs) {
      const { jobId, cityId, userTaskId, dataType, data = "" } = this.urlParams
      const taskJson = {
        jobId,
        cityId,
        userTaskId,
        dataType, // 1sku 采集 2 poi采集
        companyId: this.companyId,
        data,
      }
      const waitForSaveList = imgs.filter(item => !item.saveStatus)
      const submitRes = waitForSaveList.map(async (item, index) => {
        item.status = 'uploading'
        item.message = '提交中'
        const { deviceList, picDurationList, picFormatList, picHeightList, picSizeList, picWidthList, uploadStartTimeList, uploadEndTimeList } = submitInfo
        return await submitSourceUrl({
          resourceUrl: item.ossUrl,
          taskInfo: JSON.stringify({
            urlList: [item.ossUrl],
            channel: 'LGTJ',
            fileType: item.type === '图片' ? 'img' : 'video',
            extra: [Object.assign(taskJson, {
              device: deviceList[index],
              version: '',
              picSize: picSizeList[index],
              picFormat: picFormatList[index],
              picCreate: item.fileCreateTime,
              picDuration: picDurationList[index],
              picWidth: picWidthList[index],
              picHeight: picHeightList[index],
              picCommit: uploadStartTimeList[index],
              picCommitFinish: uploadEndTimeList[index],
              picSDK: 0,
              picSDKFinish: 0,
              compressFileUrl: '',
            })],
          }),
          dataType: 2,
          taskType: this.taskType
        }).then(res => {
          if (res && res.status === '200') {
            item.status = 'done'
            item.file.saveStatusTxt = '已提交'
            item.saveStatus = true
          } else {
            item.status = 'done'
          }
        }).catch(() => {
          item.status = 'done'
        })
      })
      await Promise.all(submitRes)
      
      const submitSuccessList = waitForSaveList.filter(item => item.saveStatus)

      const pointRes = waitForSaveList.map(async item => {
        const pointValue = JSON.stringify({
          userTaskId: this.urlParams.userTaskId,
          isCut: false,
          normalFileName: item.ossUrl,
          fileCreatedTime: item.fileCreateTime,
          startUploadTime: item.uploadStartTime,
          endUploadTime: item.uploadEndTime,
          uploadStatus: item.saveStatus ? 1 : 2,
          source: 2
        })
        return await addBuriedPoint({
          pointKey: 'fileUploadPoint',
          pointValue
        })
      })
      await Promise.all(pointRes)
      updateUniqueFileList(submitSuccessList)
      const failList = imgs.filter( item => !item.saveStatus)
      const dateTime = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
      let msg = `${dateTime} 提交成功 ${submitSuccessList.length} 个 图片/视频，继续加油哦！`
      let confirmButtonText = '继续上传'
      let type = 'confirm'
      if (failList.length) {
        msg += `还有 ${failList.length} 上传失败，请重试~`
        confirmButtonText = '再次提交'
        type = 'alert'
      }
      const options = {
        message: msg,
        confirmButtonText,
        cancelButtonText: '完成关闭'
      }

      try {
        await Dialog[type](options)
        if (!failList.length) {
          this.$refs.poiImg.clearImages()
          this.$refs.addressImg.clearImages()
          this.$router.go(0)
        }
      } catch(e) {
        window.close()
        this.isClose = false
      }
    },
    onFileChange() {
      this.taskStartTime = Date.now()
    },
    async handleSubmit() {
      if (this.loading) return
      this.loading = true
      try {
        const imgs = this.$refs.poiImg.getImagesList()
        const addressImg = this.$refs.addressImg.getImagesList()
        const submitInfo = await checkUploadImages(imgs)
        const addressImgInfo = await checkUploadImages(addressImg)
        if (submitInfo && addressImgInfo) {
          const verifyValidAddress = await this.$refs.addressImg.checkFileValid()
          const verifyValidPoi = await this.$refs.poiImg.checkFileValid()
          const verifyValid = !verifyValidAddress.valid ? verifyValidAddress : (!verifyValidPoi.valid ? verifyValidPoi : null)
          if (verifyValid) {
            Toast.fail(verifyValid.messageType ? verifyValid.message : `${verifyValid.message}，请重新上传后提交`)
            return
          }

          const options = {
            message: `正在提交${this.poiTaskName}，请再次确认`,
            confirmButtonText: '确认提交',
            cancelButtonText: '我再想想'
          }
          await Dialog.confirm(options)
          this.fetchTaskNew(submitInfo, [...addressImg, ...imgs])
        }
      } finally {
        this.loading = false
      }
    },
    handleCopy() {
      copyText(this.address)
    },
    clickFeedback() {
      this.$router.push({ path: '/abnormalFeedback'+ location.search});
    },
  },
}
</script>

<style lang="less" scoped>
.poiTaskDistribution {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  font-size: 16px;
  .poiTaskDistributionMain {
    width: calc(100% - 30px);
    padding: 15px;
    margin-top: 25px;
    font-size: 14px;
    background-color: #e2e2e3;
    border-radius: 20px;
    .information {
      line-height: 30px;
      color: #6f6f71;
    }
    .copy {
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        max-width: 80%;
      }
      .copyBtn {
        width: 20%;
        margin-left: 10px;
        color: #fff;
        background-color: #007aff;
      }
    }
    .poiTaskDistributionUploadFile {
      margin-top: 20px;
      .lab {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        margin-bottom: 10px;

        .lab-demo {
          color: #999;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
  .submit {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20%;
    .submitBtn {
      width: 100%;
      color: #fff;
      background-color: #007aff;
      border-radius: 16px;
    }
    .feedback {
      padding-top: 10px;
      font-size: 14px;
      text-align: center;
      .linkText{
        font-weight: bold;
        color: #3e7bf3
      }
    }
  }
}
.preview-address {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 40px 0 10px;
}
</style>
